import { useQuery } from '@tanstack/react-query'

import { Select } from '@/components/select'
import { countriesQuery } from '@/queries/use-countries-queries'

interface CountrySelectProps {
  value?: string | null
  onChange: (value: string) => void
  placeholder?: string
  disabled?: boolean
}

export function CountrySelect({
  disabled,
  onChange,
  placeholder = 'Select nationality...',
  value,
}: CountrySelectProps) {
  const { data: countries, isLoading } = useQuery(countriesQuery)

  return (
    <Select
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isDisabled={disabled}
      isLoading={isLoading}
      onChange={(value) => value && onChange(value.id)}
      options={countries}
      placeholder={placeholder}
      value={countries?.find(({ id }) => value === id)}
    />
  )
}
