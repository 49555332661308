import { useEffect } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { EmailTemplatesSelect } from '@/components/form/email-template-select'
import { Tiptap } from '@/components/tiptap/tiptap'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { SUGGESTION_ITEMS } from '@/features/settings/emails-templates/constant'
import { emailTemplateQuery } from '@/queries/use-email-templates-queries'
import { useCreateEmailsToSendCustom } from '@/queries/use-emails-to-send-mutations'
import { emailsToSendCustomSchema, EmailsToSendCustomValues } from '@/schemas/emails-to-send/custom'
import {
  convertMentionHtmlToVariables,
  convertVariablesToMentionHtml,
  removeHTMLTags,
  replaceAtSymbolWithCurlyBraces,
} from '@/utils/string'

interface ContactFormProps {
  onClose: () => void
}

export function ContactForm({ onClose }: ContactFormProps) {
  const { applicationId } = useParams({ from: '/_authenticated/jobs/$jobId/applications/$applicationId' })
  const form = useForm<EmailsToSendCustomValues>({
    defaultValues: {
      applicationId,
      body: '',
      emailTemplateId: '',
      subject: '',
    },
    resolver: zodResolver(emailsToSendCustomSchema),
  })
  const { control, handleSubmit, setValue, watch } = form
  const emailTemplateId = watch('emailTemplateId')
  const { data: emailTemplate } = useQuery(emailTemplateQuery(emailTemplateId))
  const { mutate: createEmailsToSendCustom } = useCreateEmailsToSendCustom()

  useEffect(() => {
    if (emailTemplate) {
      setValue('subject', convertVariablesToMentionHtml(emailTemplate.subject))
      setValue('body', convertVariablesToMentionHtml(emailTemplate.body))
    }
  }, [emailTemplate, setValue])

  const onSubmit = (values: EmailsToSendCustomValues) => {
    const { applicationId, body, subject } = values

    values.body = replaceAtSymbolWithCurlyBraces(convertMentionHtmlToVariables(body))
    values.subject = removeHTMLTags(convertMentionHtmlToVariables(subject))

    createEmailsToSendCustom(
      {
        body: {
          applicationId,
          body: values.body,
          subject: values.subject,
        },
      },
      {
        onSuccess: () => {
          toast.success('Email sent to applicant successfully.')
          onClose()
        },
      },
    )
  }

  return (
    <Form {...form}>
      <form className="flex flex-col gap-y-4" onSubmit={handleSubmit(onSubmit)}>
        <CustomFormField
          control={control}
          fieldType={FormFieldType.SKELETON}
          label="Template"
          name="emailTemplateId"
          renderSkeleton={(field) => <EmailTemplatesSelect onChange={field.onChange} value={field.value} />}
        />
        <CustomFormField
          control={control}
          description="Type @ to display a list of variables and select them to insert into the text."
          descriptionTooltipContent={SUGGESTION_ITEMS.join('\n')}
          fieldType={FormFieldType.SKELETON}
          label="Subject"
          name="subject"
          placeholder="Interview Invitation"
          renderSkeleton={(field) => (
            <Tiptap
              extensions="input"
              name={field.name}
              onChange={field.onChange}
              suggestionItems={SUGGESTION_ITEMS}
              value={field.value}
            />
          )}
        />
        <CustomFormField
          control={control}
          description="Type @ to display a list of variables (applicant lastname, job title, etc...) and select them to insert into the text."
          descriptionTooltipContent={SUGGESTION_ITEMS.join('\n')}
          fieldType={FormFieldType.SKELETON}
          label="Body"
          name="body"
          renderSkeleton={(field) => (
            <Tiptap
              extensions="emailTemplate"
              name={field.name}
              onChange={field.onChange}
              suggestionItems={SUGGESTION_ITEMS}
              value={field.value}
            />
          )}
        />
        <Button className="self-center" type="submit">
          Send email
        </Button>
      </form>
    </Form>
  )
}
