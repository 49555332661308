import { useEffect } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { EmailTemplatesSelect } from '@/components/form/email-template-select'
import { Tiptap } from '@/components/tiptap/tiptap'
import { Button } from '@/components/ui/button'
import { Form, FormControl } from '@/components/ui/form'
import { SheetClose, SheetFooter } from '@/components/ui/sheet'
import { SUGGESTION_ITEMS } from '@/features/settings/emails-templates/constant'
import { useBulkActions } from '@/hooks/use-bulk-actions'
import { emailTemplateQuery } from '@/queries/use-email-templates-queries'
import { useUpdateJobBulkApplications } from '@/queries/use-jobs-mutations'
import { putJobBulkSendEmailSchema, PutJobBulkSendEmailValues } from '@/schemas/jobs/jobId/board'
import {
  convertMentionHtmlToVariables,
  convertVariablesToMentionHtml,
  removeHTMLTags,
  replaceAtSymbolWithCurlyBraces,
} from '@/utils/string'

interface SendEmailFormProps {
  onClose: () => void
}

export function SendEmailForm({ onClose }: SendEmailFormProps) {
  const { jobId } = useParams({ from: '/_authenticated/jobs/$jobId' })
  const { clearSelected, selected, toggleOpen } = useBulkActions()
  const { mutate: updateJobBulkApplications } = useUpdateJobBulkApplications(jobId)
  const form = useForm<PutJobBulkSendEmailValues>({
    defaultValues: {
      body: '',
      emailTemplateId: '',
      subject: '',
    },
    resolver: zodResolver(putJobBulkSendEmailSchema),
  })

  const { control, handleSubmit, reset, setValue, watch } = form
  const emailTemplateId = watch('emailTemplateId')
  const { data: emailTemplate } = useQuery(emailTemplateQuery(emailTemplateId))

  const onSubmit = (values: PutJobBulkSendEmailValues) => {
    const { body, subject } = values
    values.body = replaceAtSymbolWithCurlyBraces(convertMentionHtmlToVariables(body))
    values.subject = removeHTMLTags(convertMentionHtmlToVariables(subject))
    updateJobBulkApplications(
      {
        action: 'SEND_EMAIL',
        applicationIds: selected,
        value: {
          ...values,
        },
      },
      {
        onSuccess: () => {
          toast.success(`Email sent to ${selected.length > 1 ? 'applicants' : 'applicant'} successfully.`)
          clearSelected()
          onClose()
          toggleOpen()
        },
      },
    )
  }

  useEffect(() => {
    if (emailTemplate) {
      setValue('subject', convertVariablesToMentionHtml(emailTemplate.subject))
      setValue('body', convertVariablesToMentionHtml(emailTemplate.body))
    }
  }, [emailTemplate, reset, setValue])

  return (
    <Form {...form}>
      <form className="mt-8 flex flex-col gap-y-4" onSubmit={handleSubmit(onSubmit)}>
        <CustomFormField
          control={control}
          fieldType={FormFieldType.SKELETON}
          label="Email template"
          name="emailTemplateId"
          renderSkeleton={(field) => (
            <FormControl>
              <EmailTemplatesSelect onChange={field.onChange} value={field.value} />
            </FormControl>
          )}
        />
        <CustomFormField
          control={control}
          description="Type @ to display a list of variables and select them to insert into the text."
          descriptionTooltipContent={SUGGESTION_ITEMS.join('\n')}
          fieldType={FormFieldType.SKELETON}
          label="Subject"
          name="subject"
          placeholder="Interview Invitation"
          renderSkeleton={(field) => (
            <Tiptap
              extensions="input"
              name={field.name}
              onChange={field.onChange}
              suggestionItems={SUGGESTION_ITEMS}
              value={field.value}
            />
          )}
        />
        <CustomFormField
          control={control}
          description="Type @ to display a list of variables (applicant lastname, job title, etc...) and select them to insert into the text."
          descriptionTooltipContent={SUGGESTION_ITEMS.join('\n')}
          fieldType={FormFieldType.SKELETON}
          label="Body"
          name="body"
          renderSkeleton={(field) => (
            <Tiptap
              extensions="emailTemplate"
              name={field.name}
              onChange={field.onChange}
              suggestionItems={SUGGESTION_ITEMS}
              value={field.value}
            />
          )}
        />
        <SheetFooter className="mt-8">
          <SheetClose asChild>
            <Button variant="outline">Cancel</Button>
          </SheetClose>
          <Button type="submit">Send email</Button>
        </SheetFooter>
      </form>
    </Form>
  )
}
