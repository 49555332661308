/*
 ** Mutations
 */

import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  createJob,
  createJobDescription,
  createJobFromTemplate,
  createJobsStage,
  createJobsStagesAutomationsComment,
  createJobsStagesAutomationsEmailCandidate,
  createJobsStagesAutomationsEmailRecruiter,
  createJobsStagesAutomationsScheduleInterview,
  createJobsStagesAutomationsTaskRecruiter,
  deleteJobsStage,
  deleteJobsStagesAutomations,
  publishJob,
  updateJobBulkApplications,
  updateJobsDescription,
  updateJobsInfo,
  updateJobsInternalInformations,
  updateJobsRecruiters,
  updateJobsStage,
  updateJobsStageRefusalStrategy,
  updateJobsStagesAutomationsActive,
  updateJobsStagesAutomationsComment,
  updateJobsStagesAutomationsEmailCandidate,
  updateJobsStagesAutomationsEmailRecruiter,
  updateJobsStagesAutomationsScheduleInterview,
  updateJobsStagesAutomationsTaskRecruiter,
  updateJobsStagesOrder,
  updateJobsStatus,
} from '@/api/jobs'
import { PutJobStageRefusalStrategyValues, PutOrPostJobStageValues } from '@/schemas/jobs/jobId/stage'
import { UpdateAutomationActiveBody } from '@/types/automation'
import { CreateOrUpdateAutomationCommentBody } from '@/types/automation/create-or-update-comment'
import { CreateOrUpdateAutomationEmailCandidateBody } from '@/types/automation/create-or-update-email-candidate'
import { CreateOrUpdateAutomationEmailRecruiterBody } from '@/types/automation/create-or-update-email-recruiter'
import { CreateOrUpdateAutomationScheduleInterviewBody } from '@/types/automation/create-or-update-schedule-interview'
import { CreateOrUpdateAutomationTaskRecruiterBody } from '@/types/automation/create-or-update-task-recruiter'
import {
  JobCreateOrUpdateInformationsBody,
  JobUpdateDescriptionBody,
  JobUpdateStagesOrderBody,
  PutJobInternalInformationsBody,
} from '@/types/job'
import { BulkApplicationsBody } from '@/types/job/bulk-applications'
import { JobUpdateStatusBody } from '@/types/job/update-status'

import {
  jobsBoardAutomationsQuery,
  jobsBoardQuery,
  jobsDescriptionQuery,
  jobsInfoQuery,
  jobsRecruitersQuery,
  jobsStagesAutomationsQuery,
  jobsStagesQuery,
  jobsStatusQuery,
} from './use-jobs-queries'

// POST /jobs
export const useCreateJob = () =>
  useMutation({
    mutationFn: (body: JobCreateOrUpdateInformationsBody) => createJob(body),
  })

// POST /jobs/from-template
export const useCreateJobFromTemplate = () =>
  useMutation({
    mutationFn: (id: string) => createJobFromTemplate(id),
  })

// PUT /jobs/${jobId}/info
export const useUpdateJobsInfo = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body, jobId }: { jobId: string; body: JobCreateOrUpdateInformationsBody }) =>
      updateJobsInfo(jobId, body),
    onSuccess: (data) => {
      queryClient.setQueryData(jobsInfoQuery(data.id).queryKey, data)
    },
  })
}

// PUT /jobs/${jobId}/internal-informations
export const useUpdateJobsInternalInformations = () => {
  // const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body, jobId }: { jobId: string; body: PutJobInternalInformationsBody }) =>
      updateJobsInternalInformations(jobId, body),
  })
}

// PUT /jobs/${jobId}/status
export const useUpdateJobsStatus = (jobId: string) => {
  return useMutation({
    meta: {
      invalidates: [jobsBoardQuery(jobId).queryKey],
    },
    mutationFn: ({ body }: { body: JobUpdateStatusBody }) => updateJobsStatus(jobId, body),
  })
}

// PUT /jobs/${jobId}/description
export const useUpdateJobsDescription = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body, jobId }: { jobId: string; body: JobUpdateDescriptionBody }) =>
      updateJobsDescription(jobId, body),
    onSuccess: (data) => {
      queryClient.setQueryData(jobsDescriptionQuery(data.id).queryKey, data)
    },
  })
}

/**
 * POST /jobs/:jobId/description
 */
export const useCreateJobDescription = () => {
  return useMutation({
    mutationFn: ({ description, jobId }: { jobId: string; description: string }) =>
      createJobDescription(jobId, { description }),
  })
}

// POST /jobs/${jobId}/stages
export const useCreateJobsStage = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: PutOrPostJobStageValues) => createJobsStage(jobId, body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: jobsStagesQuery(jobId).queryKey })
    },
  })
}

// PUT /jobs/${jobId}/stages
export const useUpdateJobsStagesOrder = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: JobUpdateStagesOrderBody) => updateJobsStagesOrder(jobId, body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: jobsStagesQuery(jobId).queryKey })
    },
  })
}

// PUT /jobs/${jobId}/stages/${stageId}
export const useUpdateJobsStage = (jobId: string, id: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: PutOrPostJobStageValues) => updateJobsStage(jobId, id, body),
    onSuccess: (data, variables) => {
      const { color, name } = variables
      queryClient.setQueryData(jobsStagesQuery(jobId).queryKey, (oldData) => {
        if (!oldData) return

        return {
          ...oldData,
          stages: oldData?.stages.map((stage) => {
            if (stage.id === data.id) {
              return { ...stage, color, name }
            }
            return stage
          }),
        }
      })
    },
  })
}

// DELETE /jobs/${jobId}/stages/${stageId}
export const useDeleteJobsStage = (jobId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => deleteJobsStage(jobId, id),
    onSuccess(data) {
      queryClient.setQueryData(jobsStagesQuery(jobId).queryKey, (oldData) => {
        if (!oldData) return

        return {
          ...oldData,
          stages: oldData?.stages.filter((stage) => stage.id !== data.id),
        }
      })
    },
  })
}

// PUT /jobs/${jobId}/stages/${stageId}/refusal-strategy
export const useUpdateJobsStageRefusalStrategy = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body, stageId }: { stageId: string; body: PutJobStageRefusalStrategyValues }) =>
      updateJobsStageRefusalStrategy(jobId, stageId, body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: jobsStagesQuery(jobId).queryKey })
    },
  })
}

// POST /jobs/${jobId}/stages/${stageId}/automations/email-candidate
export const useCreateJobsStagesAutomationsEmailCandidate = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body, stageId }: { body: CreateOrUpdateAutomationEmailCandidateBody; stageId: string }) =>
      createJobsStagesAutomationsEmailCandidate(jobId, stageId, body),
    onSuccess: async (_, { stageId }) => {
      await queryClient.invalidateQueries({ queryKey: jobsStagesAutomationsQuery(jobId, stageId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsStagesQuery(jobId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsBoardAutomationsQuery(jobId).queryKey })
    },
  })
}

// PUT /jobs/${jobId}/stages/${stageId}/automations/email-candidate/${automationId}
export const useUpdateJobsStagesAutomationsEmailCandidate = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      automationId,
      body,
      stageId,
    }: {
      stageId: string
      automationId: string
      body: CreateOrUpdateAutomationEmailCandidateBody
    }) => updateJobsStagesAutomationsEmailCandidate({ automationId, body, jobId, stageId }),
    onSuccess: async (_, { stageId }) => {
      await queryClient.invalidateQueries({ queryKey: jobsStagesAutomationsQuery(jobId, stageId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsStagesQuery(jobId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsBoardAutomationsQuery(jobId).queryKey })
    },
  })
}

// POST /jobs/${jobId}/stages/${stageId}/automations/email-recruiter
export const useCreateJobsStagesAutomationsEmailRecruiter = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body, stageId }: { body: CreateOrUpdateAutomationEmailRecruiterBody; stageId: string }) =>
      createJobsStagesAutomationsEmailRecruiter(jobId, stageId, body),
    onSuccess: async (_, { stageId }) => {
      await queryClient.invalidateQueries({ queryKey: jobsStagesAutomationsQuery(jobId, stageId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsStagesQuery(jobId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsBoardAutomationsQuery(jobId).queryKey })
    },
  })
}

// PUT /jobs/${jobId}/stages/${stageId}/automations/email-recruiter/${automationId}
export const useUpdateJobsStagesAutomationsEmailRecruiter = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      automationId,
      body,
      stageId,
    }: {
      stageId: string
      automationId: string
      body: CreateOrUpdateAutomationEmailRecruiterBody
    }) => updateJobsStagesAutomationsEmailRecruiter({ automationId, body, jobId, stageId }),
    onSuccess: async (_, { stageId }) => {
      await queryClient.invalidateQueries({ queryKey: jobsStagesAutomationsQuery(jobId, stageId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsStagesQuery(jobId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsBoardAutomationsQuery(jobId).queryKey })
    },
  })
}

// POST /jobs/${jobId}/stages/${stageId}/automations/comment
export const useCreateJobsStagesAutomationsComment = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body, stageId }: { body: CreateOrUpdateAutomationCommentBody; stageId: string }) =>
      createJobsStagesAutomationsComment(jobId, stageId, body),
    onSuccess: async (_, { stageId }) => {
      await queryClient.invalidateQueries({ queryKey: jobsStagesAutomationsQuery(jobId, stageId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsStagesQuery(jobId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsBoardAutomationsQuery(jobId).queryKey })
    },
  })
}

// PUT /jobs/${jobId}/stages/${stageId}/automations/comment/${automationId}
export const useUpdateJobsStagesAutomationsComment = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      automationId,
      body,
      stageId,
    }: {
      stageId: string
      automationId: string
      body: CreateOrUpdateAutomationCommentBody
    }) => updateJobsStagesAutomationsComment({ automationId, body, jobId, stageId }),
    onSuccess: async (_, { stageId }) => {
      await queryClient.invalidateQueries({ queryKey: jobsStagesAutomationsQuery(jobId, stageId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsStagesQuery(jobId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsBoardAutomationsQuery(jobId).queryKey })
    },
  })
}

// POST /jobs/${jobId}/stages/${stageId}/automations/task-recruiter
export const useCreateJobsStagesAutomationsTaskRecruiter = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body, stageId }: { body: CreateOrUpdateAutomationTaskRecruiterBody; stageId: string }) =>
      createJobsStagesAutomationsTaskRecruiter(jobId, stageId, body),
    onSuccess: async (_, { stageId }) => {
      await queryClient.invalidateQueries({ queryKey: jobsStagesAutomationsQuery(jobId, stageId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsStagesQuery(jobId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsBoardAutomationsQuery(jobId).queryKey })
    },
  })
}

// PUT /jobs/${jobId}/stages/${stageId}/automations/task-recruiter/${automationId}
export const useUpdateJobsStagesAutomationsTaskRecruiter = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      automationId,
      body,
      stageId,
    }: {
      stageId: string
      automationId: string
      body: CreateOrUpdateAutomationTaskRecruiterBody
    }) => updateJobsStagesAutomationsTaskRecruiter({ automationId, body, jobId, stageId }),
    onSuccess: async (_, { stageId }) => {
      await queryClient.invalidateQueries({ queryKey: jobsStagesAutomationsQuery(jobId, stageId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsStagesQuery(jobId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsBoardAutomationsQuery(jobId).queryKey })
    },
  })
}

// POST /jobs/${jobId}/stages/${stageId}/automations/schedule-interview
export const useCreateJobsStagesAutomationsScheduleInterview = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body, stageId }: { body: CreateOrUpdateAutomationScheduleInterviewBody; stageId: string }) =>
      createJobsStagesAutomationsScheduleInterview(jobId, stageId, body),
    onSuccess: async (_, { stageId }) => {
      await queryClient.invalidateQueries({ queryKey: jobsStagesAutomationsQuery(jobId, stageId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsStagesQuery(jobId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsBoardAutomationsQuery(jobId).queryKey })
    },
  })
}

// PUT /jobs/${jobId}/stages/${stageId}/automations/schedule-interview/${automationId}
export const useUpdateJobsStagesAutomationsScheduleInterview = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      automationId,
      body,
      stageId,
    }: {
      stageId: string
      automationId: string
      body: CreateOrUpdateAutomationScheduleInterviewBody
    }) => updateJobsStagesAutomationsScheduleInterview({ automationId, body, jobId, stageId }),
    onSuccess: async (_, { stageId }) => {
      await queryClient.invalidateQueries({ queryKey: jobsStagesAutomationsQuery(jobId, stageId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsStagesQuery(jobId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsBoardAutomationsQuery(jobId).queryKey })
    },
  })
}

// DELETE /jobs/${jobId}/stages/${stageId}/automations/${automationId}
export const useDeleteJobsStagesAutomation = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ automationId, stageId }: { automationId: string; stageId: string }) =>
      deleteJobsStagesAutomations(jobId, stageId, automationId),
    onSuccess: async (data, { stageId }) => {
      queryClient.setQueryData(jobsStagesAutomationsQuery(jobId, stageId).queryKey, (oldData) => {
        if (!oldData) return

        return oldData.filter((automation) => automation.id !== data.id)
      })
      queryClient.setQueryData(jobsStagesQuery(jobId).queryKey, (oldData) => {
        if (!oldData) return

        return {
          ...oldData,
          stages: oldData?.stages.map((stage) => {
            if (stage.id === stageId) {
              return {
                ...stage,
                countAutomations: stage.countAutomations - 1,
              }
            }
            return stage
          }),
        }
      })
      await queryClient.invalidateQueries({ queryKey: jobsBoardAutomationsQuery(jobId).queryKey })
    },
  })
}

// PUT /jobs/${jobId}/stages/${stageId}/automations/${automationId}/active
export const useUpdateJobsStagesAutomationsActive = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      automationId,
      body,
      stageId,
    }: {
      automationId: string
      body: UpdateAutomationActiveBody
      stageId: string
    }) => updateJobsStagesAutomationsActive(jobId, stageId, automationId, body),
    onSuccess: async ({ id }, { stageId }) => {
      queryClient.setQueryData(jobsStagesAutomationsQuery(jobId, stageId).queryKey, (oldData) => {
        if (!oldData) return

        return oldData.map((automation) => {
          if (automation.id === id) {
            return { ...automation, isActive: !automation.isActive }
          }
          return automation
        })
      })
      await queryClient.invalidateQueries({ queryKey: jobsBoardAutomationsQuery(jobId).queryKey })
    },
  })
}

// PUT /jobs/${jobId}/recruiters
export const useUpdateJobsRecruiters = (jobId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (ids: string[]) => updateJobsRecruiters(jobId, ids),
    onSuccess: (data) => {
      queryClient.setQueryData(jobsRecruitersQuery(jobId).queryKey, data)
    },
  })
}

// PUT /jobs/${jobId}/bulk
export const useUpdateJobBulkApplications = (jobId: string) => {
  return useMutation({
    meta: {
      invalidates: [jobsBoardQuery(jobId).queryKey],
    },
    mutationFn: (body: BulkApplicationsBody) => updateJobBulkApplications(jobId, body),
  })
}

// PUT /jobs/${jobId}/publish
export const usePublishJob = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ jobId }: { jobId: string }) => publishJob(jobId),
    onSuccess: async (_, { jobId }) => {
      await queryClient.invalidateQueries({ queryKey: jobsStatusQuery(jobId).queryKey })
    },
  })
}
