import { getRefusalReasons } from '@/api/refusal-reasons'

/**
 * GET /refusal-reasons
 */
export const refusalReasonsQuery = {
  queryFn: getRefusalReasons,
  queryKey: ['refusalReasons'],
  staleTime: Infinity,
}
