import { useQuery } from '@tanstack/react-query'
import { MultiValue, SingleValue } from 'react-select'

import { Select } from '@/components/select'
import { recruitersMinimalQuery } from '@/queries/use-recruiters-queries'
import { Recruiter } from '@/types/recruiter'
import { getFullName } from '@/utils/string'

interface RecruiterSelectProps {
  value: string | string[]
  onChange: (value?: string | string[]) => void
  hideSelectedOptionsOnInput?: boolean
  placeholder?: string
}

export function RecruiterSelect({
  hideSelectedOptionsOnInput,
  onChange,
  placeholder = 'Select recruiter...',
  value,
}: RecruiterSelectProps) {
  const { data: recruiters, isLoading } = useQuery(recruitersMinimalQuery)
  const isMulti = Array.isArray(value)

  return (
    <Select
      getOptionLabel={(option) => getFullName(option)}
      getOptionValue={(option) => option.id}
      hideSelectedOptionsOnInput={hideSelectedOptionsOnInput}
      isLoading={isLoading}
      isMulti={isMulti}
      menuPosition="absolute"
      onChange={(value) => {
        if (isMulti) {
          onChange((value as MultiValue<Recruiter>).map((opt) => opt.id))
        } else {
          onChange((value as SingleValue<Recruiter>)?.id)
        }
      }}
      options={recruiters}
      placeholder={placeholder}
      value={
        isMulti ? recruiters?.filter((opt) => value?.includes(opt.id)) : recruiters?.find((opt) => opt.id === value)
      }
    />
  )
}
