import { get } from '@/services/get'
import { post } from '@/services/post'
import { put } from '@/services/put'
import { Organization, OrganizationCreateOrUpdateResponse } from '@/types/organization'

/**
 * GET /organizations
 */
export function getOrganizations(view?: 'minimal') {
  return get<Organization[]>('/organizations', {
    params: {
      output: view,
    },
  })
}

/**
 * GET /organizations/:id
 */
export function createOrganization(body: FormData) {
  return post<FormData, OrganizationCreateOrUpdateResponse>('/organizations', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

/**
 * PUT /organizations/:id
 */
export function updateOrganization(id: string, body: FormData) {
  return put<FormData, OrganizationCreateOrUpdateResponse>(`/organizations/${id}`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
