import { useQuery } from '@tanstack/react-query'
import { AtSign, BotIcon, BriefcaseBusiness, MapPin, Phone, SendIcon, UserSearch } from 'lucide-react'

import { CountryFlag } from '@/components/country-flag'
import { LinkedinLink } from '@/components/linkedin-link'
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Skeleton } from '@/components/ui/skeleton'
import { Text } from '@/components/ui/text'
import { Title } from '@/components/ui/title'
import { applicationsApplicantQuery } from '@/hooks/use-applications-queries'
import { getFormattedPhoneNumber } from '@/utils/phone'
import { getFullName, getFullNameInitial, getJobName } from '@/utils/string'

const TYPE_OF_SOURCE_MAPPING = {
  DIRECT_APPLY: {
    icon: SendIcon,
    label: 'Direct Apply',
  },
  SOURCING: {
    icon: UserSearch,
    label: 'Sourcing',
  },
  SUGGESTED: {
    icon: BotIcon,
    label: 'Suggested',
  },
}

interface ApplicantInformationsProps {
  applicationId: string
}

export function ApplicantInformations({ applicationId }: ApplicantInformationsProps) {
  const { data: applicant, isLoading } = useQuery(applicationsApplicantQuery(applicationId))

  if (isLoading || !applicant) return <ApplicantInformationSkeletons />

  const typeOfSource = applicant.typeOfSource
    ? TYPE_OF_SOURCE_MAPPING[applicant.typeOfSource as keyof typeof TYPE_OF_SOURCE_MAPPING]
    : undefined

  const Icon = typeOfSource?.icon
  const label = typeOfSource?.label

  return (
    <div className="flex gap-x-4 border-b px-8 py-4">
      <Avatar size="xl">
        <AvatarFallback>{getFullNameInitial(applicant)}</AvatarFallback>
      </Avatar>

      <div className="flex w-full flex-col gap-y-1">
        <div className="flex items-center gap-x-2">
          <Title level={3}>{getFullName(applicant)}</Title>
          <CountryFlag code={applicant.nationality.id} name={applicant.nationality.name} side="bottom" size="lg" />
          {applicant.linkedinUrl && <LinkedinLink size="lg" url={applicant.linkedinUrl} />}
        </div>
        {applicant.email && (
          <div className="flex items-center gap-x-1">
            <AtSign className="shrink-0" size={12} />
            <Text size="sm" weight="light">
              {applicant.email || '-'}
            </Text>
          </div>
        )}
        <div className="flex items-center gap-x-1">
          <Phone className="shrink-0" size={12} />
          <Text size="sm" weight="light">
            {applicant.phone ? getFormattedPhoneNumber(applicant.phone) : '-'}
          </Text>
        </div>
        <div className="flex items-center gap-x-1">
          <BriefcaseBusiness className="shrink-0" size={12} />
          <Text size="sm" weight="light">
            {getJobName(applicant)}
          </Text>
        </div>
        <div className="flex items-center gap-x-1">
          <MapPin className="shrink-0" size={12} />
          <Text size="sm" weight="light">
            {applicant.city && applicant.city + ', '}
            {applicant.country.name}
          </Text>
        </div>
        {Icon && label && (
          <div className="flex items-center gap-x-1">
            <Icon className="shrink-0" size={12} />
            <Text size="sm" weight="light">
              {label}
            </Text>
          </div>
        )}
      </div>
    </div>
  )
}

function ApplicantInformationSkeletons() {
  return (
    <div className="flex gap-x-4 border-b px-8 py-4">
      <Skeleton className="size-16 rounded-full" />
      <div className="flex flex-col gap-y-1">
        <Skeleton className="h-10 w-56" />
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-4 w-32" />
      </div>
    </div>
  )
}
