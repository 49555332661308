import { ComponentPropsWithoutRef } from 'react'

import { cva, VariantProps } from 'class-variance-authority'
import * as Flags from 'country-flag-icons/react/3x2'

import { cn } from '@/lib/utils'

import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip'

const countryFlagVariants = cva('', {
  defaultVariants: {
    size: 'default',
  },
  variants: {
    size: {
      default: 'w-4',
      lg: 'w-8',
    },
  },
})

interface CountryFlagProps extends VariantProps<typeof countryFlagVariants> {
  code: string
  name: string
  side?: ComponentPropsWithoutRef<typeof TooltipContent>['side']
}

export function CountryFlag({ code, name, side, size }: CountryFlagProps) {
  if (!code || !name) return null
  // eslint-disable-next-line import/namespace
  const Flag = Flags[code as keyof typeof Flags]

  return (
    <Tooltip>
      <TooltipTrigger>
        <Flag className={cn(countryFlagVariants({ size }))} />
      </TooltipTrigger>
      <TooltipContent side={side}>{name}</TooltipContent>
    </Tooltip>
  )
}
