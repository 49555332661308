import { ComponentType } from 'react'

import { useQuery } from '@tanstack/react-query'

import { Scoring } from '@/components/scoring'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Skeleton } from '@/components/ui/skeleton'
import { applicationsApplicantInfoQuery } from '@/hooks/use-applications-queries'
import { ApplicantInfo } from '@/types/application/applicant-info'

import { Applicant } from './applicant'
import { History } from './history'
import { QualificationsData } from './qualifications-data'
import { Resume } from './resume'

interface AccorditonItemsProps {
  title: string
  value: keyof ApplicantInfo
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I know this is bad :grimacing:
  component: ComponentType<{ data: any }>
}

const ACCORDION_ITEMS: AccorditonItemsProps[] = [
  {
    component: Resume,
    title: 'Resume / CV',
    value: 'resume',
  },
  {
    component: Applicant,
    title: 'Manage Personal Data',
    value: 'applicant',
  },
  {
    component: QualificationsData,
    title: 'Qualifications Data',
    value: 'qualificationsData',
  },
  {
    component: History,
    title: 'History of applications',
    value: 'historyOfApplications',
  },
]

interface ApplicationAccordionProps {
  applicationId: string
}

export function ApplicationAccordion({ applicationId }: ApplicationAccordionProps) {
  const { data, isLoading } = useQuery(applicationsApplicantInfoQuery(applicationId))

  if (isLoading) return <ApplicationAccordionSkeleton />

  return (
    <Accordion className="space-y-4" type="multiple">
      {ACCORDION_ITEMS.map((item) => (
        <AccordionItem key={item.value} value={item.value}>
          <AccordionTrigger className="bg-muted">
            {item.title}
            {item.value === 'qualificationsData' && data?.qualificationsData.matchScore && (
              <div className="ml-auto mr-2">
                <Scoring max={5} value={data.qualificationsData.matchScore} />
              </div>
            )}
          </AccordionTrigger>
          <AccordionContent>
            <item.component
              data={
                item.value === 'applicant'
                  ? {
                      ...data?.[item.value],
                      applicationSource: data?.source,
                      applicationTypeOfSource: data?.typeOfSource,
                    }
                  : data?.[item.value]
              }
            />
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

function ApplicationAccordionSkeleton() {
  return (
    <div className="flex flex-col gap-y-4">
      {ACCORDION_ITEMS.map((item) => (
        <Skeleton className="h-14 w-full" key={item.value} />
      ))}
    </div>
  )
}
