import { createFileRoute } from '@tanstack/react-router'

import { PageContent } from '@/components/page-content'
import { PageHeaderSkeleton } from '@/components/skeleton/page-header-skeleton'
import { TableSkeleton } from '@/components/ui/table'
import { organizationsQuery } from '@/queries/use-organizations-queries'

export const Route = createFileRoute('/_authenticated/settings/')({
  loader: ({ context: { queryClient } }) => queryClient.ensureQueryData(organizationsQuery),
  pendingComponent: () => (
    <>
      <PageHeaderSkeleton cta title="Organizations" />
      <PageContent>
        <TableSkeleton />
      </PageContent>
    </>
  ),
})
