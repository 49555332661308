import { ErrorBoundary } from '@sentry/react'
import { matchQuery, MutationCache, QueryCache, QueryClient, QueryKey } from '@tanstack/react-query'
import { createRouter, Link } from '@tanstack/react-router'
import axios, { AxiosError, type AxiosRequestConfig } from 'axios'
import { Loader2 } from 'lucide-react'
import { toast } from 'sonner'

import { buttonVariants } from './components/ui/button.style'
import { Title } from './components/ui/title'
import { cn } from './lib/utils'
import { routeTree } from './routeTree.gen'

declare module '@tanstack/react-query' {
  interface Register {
    mutationMeta: {
      invalidates?: Array<QueryKey>
    }
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      staleTime: 30000,
    },
  },
  mutationCache: new MutationCache({
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        const message = (error as AxiosError<{ message: string }>).response?.data?.message

        const customErrorHandling = (error.config as AxiosRequestConfig & { customErrorHandling: boolean })
          ?.customErrorHandling

        if (customErrorHandling) return null

        switch (error.response?.status) {
          case 400: {
            const message = (error as AxiosError<{ message: string[] }>).response?.data?.message
            const title = message && message.length > 1 ? 'Fields are not valid' : 'Field is not valid'

            toast.error(title, {
              description: message?.join('\r\n'),
            })
            break
          }

          case 403: {
            return
          }

          case 409: {
            return
          }

          case 418: {
            const title = (error as AxiosError<{ title: string }>).response?.data?.title
            toast.error(title, {
              description: message,
            })
            break
          }
          case 422: {
            const errors = (error as AxiosError<{ errors: Record<string, string> }>).response?.data?.errors
            const title = errors && Object.keys(errors).length > 1 ? 'Fields are not valid' : 'Field is not valid'
            const description = errors && Object.values(errors).join('\r\n')

            toast.error(title, {
              description,
            })

            break
          }

          default:
            toast.error(message)
        }
      }
    },
    onSuccess: async (_data, _variables, _context, mutation) => {
      await queryClient.invalidateQueries({
        predicate: (query) => {
          return mutation.meta?.invalidates?.some((queryKey) => matchQuery({ queryKey }, query)) ?? false
        },
      })
    },
  }),
  queryCache: new QueryCache({
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        const message = (error as AxiosError<{ message: string }>).response?.data?.message
        toast.error(message)
      }
    },
  }),
})

export const router = createRouter({
  context: {
    auth: undefined!,
    queryClient,
  },
  defaultErrorComponent: () => {
    return (
      <ErrorBoundary>
        <div className="flex flex-col items-center gap-y-4 p-8">
          <Title level={4}>Something went wrong</Title>
          <Link className={cn(buttonVariants())} to="/">
            Go home
          </Link>
        </div>
      </ErrorBoundary>
    )
  },
  defaultPendingComponent: () => (
    <div className="flex h-screen w-screen flex-col items-center justify-center gap-y-8">
      <Loader2 className="animate-spin text-muted-foreground" size={56} />
    </div>
  ),
  routeTree,
})
