import { EmailTemplateResponse } from '@/models/email-templates/email-template-response'
import { CreateOrUpdateEmailTemplateRequest } from '@/schemas/email-template/create-or-update-email-template'
import { get } from '@/services/get'
import { post } from '@/services/post'
import { put } from '@/services/put'

/**
 * GET /organizations/:organizationId/email-templates
 */
export function getEmailTemplatesByOrganizationId(organizationId?: string) {
  return get<EmailTemplateResponse[]>(`/organizations/${organizationId}/email-templates`)
}

/**
 * POST /organizations/:organizationId/email-templates
 */
export function createEmailTemplatesByOrganizationId(organizationId: string, body: CreateOrUpdateEmailTemplateRequest) {
  return post<CreateOrUpdateEmailTemplateRequest, { id: string }>(
    `/organizations/${organizationId}/email-templates`,
    body,
  )
}

/**
 * PUT /organizations/:organizationId/email-templates/:templateId
 */
export function updateEmailTemplatesByOrganizationId(
  organizationId: string,
  body: CreateOrUpdateEmailTemplateRequest,
  templateId: string,
) {
  return put<CreateOrUpdateEmailTemplateRequest, { id: string }>(
    `/organizations/${organizationId}/email-templates/${templateId}`,
    body,
  )
}

/**
 * PUT /organizations/:organizationId/email-templates/:templateId/toggle
 */
export function updateToggleEmailTemplatesByOrganizationId(
  organizationId: string,
  body: { isActive: boolean },
  templateId: string,
) {
  return put<{ isActive: boolean }, { id: string }>(
    `/organizations/${organizationId}/email-templates/${templateId}/toggle`,
    body,
  )
}
