import { useEffect } from 'react'

import { Bold } from '@tiptap/extension-bold'
import { BulletList } from '@tiptap/extension-bullet-list'
import { Document } from '@tiptap/extension-document'
import { Heading } from '@tiptap/extension-heading'
import { History } from '@tiptap/extension-history'
import { Italic } from '@tiptap/extension-italic'
import { Link } from '@tiptap/extension-link'
import { ListItem } from '@tiptap/extension-list-item'
import { Mention } from '@tiptap/extension-mention'
import { OrderedList } from '@tiptap/extension-ordered-list'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Text } from '@tiptap/extension-text'
import { Underline } from '@tiptap/extension-underline'
import { useEditor, EditorContent } from '@tiptap/react'

import { cn } from '@/lib/utils'

import { NoLineBreak } from './no-line-break-extension'
import { Suggestion } from './suggestion'
import { TiptapToolbar } from './tiptap-toolbar'

interface TiptapProps {
  name: string
  value: string
  onChange: (value: string) => void
  placeholder?: string
  suggestionItems?: string[]
  extensions?: keyof typeof EXTENSIONS_MAPPING
  className?: string
  disabled?: boolean
}

const EXTENSIONS_MAPPING = {
  base: [
    Bold,
    Italic,
    Underline,
    ListItem,
    OrderedList.configure(),
    BulletList.configure(),
    Heading.configure({
      levels: [1, 2, 3, 4, 5, 6],
    }),
    Link.configure({
      defaultProtocol: 'https',
      openOnClick: false,
    }),
    History,
  ],
  emailTemplate: [
    Bold,
    Italic,
    Underline,
    ListItem,
    OrderedList.configure(),
    BulletList.configure(),
    Heading.configure({
      levels: [1, 2, 3],
    }),
    Link.configure({
      defaultProtocol: 'https',
      openOnClick: false,
    }),
  ],
  input: [NoLineBreak],
}

export function Tiptap({
  className,
  disabled,
  extensions = 'base',
  name,
  onChange,
  placeholder,
  suggestionItems,
  value,
}: TiptapProps) {
  const editor = useEditor({
    content: value,
    editable: !disabled,
    editorProps: {
      attributes: {
        class: cn(
          'rounded-b-md border-b w-full border-x min-h-[300px] max-h-[600px] border-input bg-background px-3 text-sm shadow-sm transition-all outline-none placeholder:text-muted-foreground/50 disabled:cursor-not-allowed disabled:opacity-50 overflow-auto',
          className,
          extensions === 'input' && 'rounded border py-1 h-9 min-h-fit overflow-hidden inline-flex items-center',
          disabled && 'cursor-not-allowed opacity-50',
        ),
      },
    },
    extensions: [
      Document,
      Text,
      Paragraph,
      ...EXTENSIONS_MAPPING[extensions],
      ...(suggestionItems
        ? [
            Mention.configure({
              HTMLAttributes: {
                class: 'bg-primary/10 text-primary rounded-sm',
              },
              suggestion: Suggestion({
                items: suggestionItems,
              }),
            }),
          ]
        : []),
    ],
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML())
    },
  })

  useEffect(() => {
    // workaround for the editor not updating the content when the value is not empty for async initial values
    if (editor?.getHTML() !== value) {
      editor?.commands.setContent(value)
    }
  }, [editor, value])

  return (
    <div
      className={cn(
        'flex max-w-full flex-col rounded-md focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 transition-all',
        extensions !== 'input' && 'group prose',
      )}
    >
      <TiptapToolbar editor={editor} />
      <EditorContent editor={editor} name={name} placeholder={placeholder} />
    </div>
  )
}
