import { queryOptions } from '@tanstack/react-query'

import { getOrganizations } from '@/api/organizations'

/**
 * GET /organizations
 */
export const organizationsQuery = queryOptions({
  queryFn: () => getOrganizations(),
  queryKey: ['organizations'],
})

/**
 * GET /organizations?view=minimal
 */
export const organizationsMinimalQuery = queryOptions({
  queryFn: () => getOrganizations('minimal'),
  queryKey: ['organizations', 'minimal'],
})
