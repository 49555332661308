import axios from 'axios'

import { LoginValues } from '@/schemas/auth'

export async function login({ email, password }: LoginValues) {
  try {
    const response = await axios.post<{ accessToken: string }>(`${import.meta.env.VITE_API_URL as string}/auth/login`, {
      email,
      password,
    })

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
