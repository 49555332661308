import { useQuery } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'
import { toast } from 'sonner'

import { Select } from '@/components/select'
import { Title } from '@/components/ui/title'
import { applicationsJobStagesQuery, useUpdateApplicationsStage } from '@/hooks/use-applications-queries'

import { ContactDialog } from './shared/contact-dialog'

import { isDroppable } from '../../$jobId/utils'

interface ApplicationStageProps {
  applicationId: string
  jobTitle?: string
}

export function ApplicationStage({ applicationId, jobTitle }: ApplicationStageProps) {
  const { jobId } = useParams({ from: '/_authenticated/jobs/$jobId/applications/$applicationId' })
  const { data, isLoading } = useQuery(applicationsJobStagesQuery(applicationId))
  const { mutate: updateApplicationsStage } = useUpdateApplicationsStage(jobId)

  const refusedStage = data?.stages?.find(({ order }) => order === 110)
  const successfulStage = data?.stages?.find(({ order }) => order === 100)

  const filteredOptions = data?.stages.map(({ id, name, order }) => ({
    isDisabled: !isDroppable(order),
    label: name,
    value: id,
  }))

  const handleUpdateStage = (stageId: string) => {
    if (!data?.currentStage) return
    if (data?.currentStage === stageId) return

    updateApplicationsStage(
      { applicationId, body: { fromStageId: data?.currentStage, toStageId: stageId } },
      {
        onSuccess: () => {
          toast.success(`Stage's application updated successfully`)
        },
      },
    )
  }

  return (
    <div className="border-b border-l p-8">
      <div className="flex flex-col gap-y-2">
        {jobTitle && <Title level={5}>{jobTitle}</Title>}
        <Select
          isDisabled={refusedStage?.id === data?.currentStage || successfulStage?.id === data?.currentStage}
          isLoading={isLoading}
          menuPosition="absolute"
          onChange={(value) => value && handleUpdateStage(value.value)}
          options={filteredOptions}
          value={filteredOptions?.find(({ value }) => value === data?.currentStage)}
        />
        <ContactDialog />
      </div>
    </div>
  )
}
