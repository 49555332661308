import { createFileRoute } from '@tanstack/react-router'

import { PageContent } from '@/components/page-content'
import { PageHeaderSkeleton } from '@/components/skeleton/page-header-skeleton'
import { Skeleton } from '@/components/ui/skeleton'
import { jobsInfoQuery } from '@/queries/use-jobs-queries'

export const Route = createFileRoute('/_authenticated/jobs/$jobId/edit/')({
  loader: ({ context: { queryClient }, params: { jobId } }) => queryClient.ensureQueryData(jobsInfoQuery(jobId)),
  pendingComponent: () => (
    <>
      <PageHeaderSkeleton title="Job informations" />
      <PageContent>
        <Skeleton className="h-96 w-full" />
      </PageContent>
    </>
  ),
})
