import { MailIcon } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { useHelpers } from '@/hooks/use-helpers'

import { ContactForm } from './contact-form'

export function ContactDialog() {
  const { isOpen, setIsOpen } = useHelpers()

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <Button className="self-start" onClick={() => setIsOpen(true)} variant="outline">
        <MailIcon size={16} />
        Contact
      </Button>
      <DialogContent className="max-h-[calc(100vh-4rem)] w-full max-w-screen-lg overflow-auto">
        <DialogHeader>
          <DialogTitle>Send Email</DialogTitle>
        </DialogHeader>
        <ContactForm onClose={() => setIsOpen(false)} />
      </DialogContent>
    </Dialog>
  )
}
