import { get } from '@/services/get'
import { JobSearch } from '@/types/job'

/**
 * URL : /jobs/search
 */
export function getJobsSearch(query?: string) {
  return get<JobSearch[]>('/jobs/search', {
    params: {
      ...(query && { q: query }),
    },
  })
}
